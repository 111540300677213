import React, { forwardRef } from 'react';
import cn from 'classnames';
import { useUserPlanContext } from '@/finsight/application/User/UserPlan/UserPlanContext';
import { Icon, IconType, Tooltip } from '@dealroadshow/uikit';
import { TRefType } from '@/Framework/UI/Organisms/ProfileWidget/Avatar/Avatar';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';

import avatarStyles from '@/Framework/UI/Organisms/ProfileWidget/Avatar/avatar.scss';
import iconStyles from '@dealroadshow/uikit/core/components/Icon/styles.scss';

interface IProps {
  firstName?: string,
  email?: string,
  tooltipContent?: string,
  showTitle?: boolean,
}

export const FinsightAvatar = forwardRef<TRefType, IProps>(({
     firstName = '',
     email = '',
     tooltipContent = 'Finsight Account',
     showTitle = false,
  }, ref) => {
    const { plan } = useUserPlanContext();
    const { isUserProfileMenuOpen } = useHeaderMenuContext();
    const name = firstName || email;
    const containerPremiumizationStyles = cn(
      avatarStyles.containerPremiumization,
      { [avatarStyles.containerPremiumizationActive]: isUserProfileMenuOpen },
    );

    return (
      <Tooltip placement="bottom" content={ tooltipContent } disabled={ !showTitle }>
        <div className={ containerPremiumizationStyles } data-test="userProfileAvatar" ref={ ref }>
          <Icon type={ IconType.user } className={ cn(avatarStyles.userIcon, iconStyles.smallIcon) } />
          <div className={ avatarStyles.namePremiumization } data-test="userProfileFullName">
            { name }
          </div>
          <div className={ avatarStyles.userPlan } data-test="userProfilePlan">
            <span>{ plan }</span>
          </div>
        </div>
      </Tooltip>
    );
  },
);

export default FinsightAvatar;
