import cn from 'classnames';
import React from 'react';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';

import styles from './missingSomethingModalButton.scss';
import spaces from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

interface IProps {
  setIsVisible: () => void,
  className?: string,
}

const MissingSomethingModalButton = (props: IProps) => (
  <div className={ cn(styles.modalButtonWrapper, props.className) }>
    <Button
      dataTest="missingSomethingButton"
      title="Missing something? Tell us."
      variant={ variantTypes.text }
      onClick={ props.setIsVisible }
      className={ cn(styles.missingButton, spaces.mrn) }
      // @ts-ignore
      tabIndex="0"
    />
  </div>
);

export default MissingSomethingModalButton;
