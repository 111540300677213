import { useState, useCallback, useEffect } from 'react';

const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e) => {
    setTargetReached(e.matches);
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${ width }px)`);
    // fix for Safary version 14<, that support deprecated addListener and does not support addEventListener
    if (typeof mediaQuery.addEventListener === 'function') {
      mediaQuery.addEventListener('change', updateTarget);
    } else {
      mediaQuery.addListener(updateTarget);
    }
    setTargetReached(mediaQuery.matches);

    return () => {
      if (typeof mediaQuery.removeEventListener === 'function') {
        mediaQuery.removeEventListener('change', updateTarget);
      } else {
        mediaQuery.removeListener(updateTarget);
      }
    };
  }, []);

  return targetReached;
};

export default useMediaQuery;
