import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  initUserPlan,
  IUserPlanContext,
  IUserPlanState,
} from '@/finsight/application/User/UserPlan/helpers';
import { useDIContext } from '@/Framework/DI/DIContext';
import ContactRepository from '@/finsight/infrastructure/repository/ContactRepository';
import UserPlan from '@/users/domain/UserPlan';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

const useUserPlan = (initial) => {
  const [currentPlan, setCurrentPlan] = useState<IUserPlanState>(initial);
  const { container } = useDIContext();
  const contactRepository = container.get<ContactRepository>(ContactRepository);
  const { currentUser } = useSessionContext();
  const isMount = useRef(false);

  const getUserRequests = async () => {
    try {
      const requests = await contactRepository.getSubscriptionRequests();
      setCurrentPlan((prevState) => ({ ...prevState, requests }));
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getUserPlan = async () => {
    try {
      const userPlan = await contactRepository.getUserCurrentPlan();
      setCurrentPlan((prevState) => ({ ...prevState, ...userPlan }));
    } catch (error) {
      setCurrentPlan(initUserPlan);
    }
  };

  useEffect(() => {
    if (!isEqual(currentPlan, initial)) {
      setCurrentPlan(initial);
    }
  }, [initial]);

  useEffect(() => {
    if (!currentPlan || isEmpty(currentPlan) || currentPlan?.hasServerError || isMount.current) {
      getUserPlan();
    }

    if (currentUser?.id && !isMount.current) {
      isMount.current = true;
    }
  }, [currentUser?.id]);

  const userPlanIs = (plan) => ({
    isUnauthorized: UserPlan.isUnauthorized(plan),
    isStarter: UserPlan.isStarter(plan),
    isPro: UserPlan.isPro(plan),
    isPremium: UserPlan.isPremium(plan),
  });

  return { ...currentPlan, ...userPlanIs(currentPlan?.plan), getUserPlan, getUserRequests };
};

type UserPlanContextType = ReturnType<typeof useUserPlan>;

export const UserPlanContext = createContext<UserPlanContextType>(null);

export const useUserPlanContext = () => {
  const context: IUserPlanContext = useContext(UserPlanContext);
  if (!context) {
    throw new Error('useUserPlanContext must be used within the UserPlanContext');
  }
  return context;
};

interface IProps {
  userPlanState?: IUserPlanState,
  children: React.ReactNode,
}

const UserPlanContextProvider = ({ children, userPlanState }: IProps) => (
  <UserPlanContext.Provider value={ useUserPlan(userPlanState) }>
    { children }
  </UserPlanContext.Provider>
);

export default UserPlanContextProvider;
