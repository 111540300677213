import IDictionaries from '@/finsight/domain/vo/dictionaries/IDictionaries';
import {
  getMappedIndustries,
  getMappedSectors,
  handleMapping,
  prepareIndustriesMenu,
  prepareSectorsMenu,
} from '@/finsight/application/actions/header/helpers';
import { HeaderMappingItem } from '@/finsight/application/actions/profile/helpers/types';
import { IRegion } from '@/finsight/domain/vo/Region';
import { IProduct } from '@/finsight/domain/vo/Product';
import { ISector } from '@/dealroadshow/domain/vo/sector/Sector';
import { IIndustry } from '@/dealroadshow/domain/vo/industry/Industry';
import { IResearchDocumentGroup } from '@/finsight/domain/vo/research/IResearchDocumentGroup';
import IRatingAgenciesMap from '@/finsight/domain/vo/dictionaries/IRatingAgenciesMap';

export const initDictionaries = {
  isHeaderInitialized: false,
  mapping: {},
  defaultActiveMapping: {},
  defaultActiveRegion: {},
  defaultActiveProduct: {},
  sectorsMenu: [],
  industriesMenu: [],
  productsList: [],
  regionsList: [],
  sectorsList: {},
  industriesList: {},
  sectorsListAll: [],
  industriesListAll: [],
  researchDocumentGroupList: [],
  ratingAgenciesMap: {},
};

export interface IDictionarySector {
  metaTitleName: string,
  pageTitleName: string,
  sectorId: string,
  sectorName: string,
  subsectorId?: string,
  subsectorName?: string,
}
export interface IDictionaryIndustry {
  industryId: string,
  industryName: string,
  metaTitleName: string,
  pageTitleName: string,
  subindustryId?: string,
  subindustryName?: string,
}

export interface IDictionariesState {
  isHeaderInitialized: boolean,
  mapping: Record<string, HeaderMappingItem> | {},
  defaultActiveMapping: any,
  defaultActiveRegion: IRegion | {},
  defaultActiveProduct: IProduct | {},
  sectorsMenu: ISector[] | [],
  industriesMenu: IIndustry[] | [],
  productsList: IProduct[] | [],
  regionsList: IRegion[] | [],
  sectorsList: { [key: string]: IDictionarySector } | {},
  industriesList: { [key: string]: IDictionaryIndustry } | {},
  sectorsListAll: ISector[] | [],
  industriesListAll: IIndustry[] | [],
  researchDocumentGroupList: IResearchDocumentGroup[] | [],
  ratingAgenciesMap: IRatingAgenciesMap | {},
  hasServerError?: boolean,
}

export const prepareDictionariesData = (
  {
    headerMapping,
    productsList,
    regionsList,
    sectorsList,
    industriesList,
    toolsList,
    ratingAgenciesMap,
    researchDocumentGroupList,
    hasServerError,
  } :IDictionaries,
): IDictionariesState => {
  if (hasServerError) {
    return {
      isHeaderInitialized: false,
      defaultActiveMapping: {},
      defaultActiveRegion: {},
      defaultActiveProduct: {},
      mapping: {},
      sectorsMenu: [],
      industriesMenu: [],
      productsList: [],
      regionsList: [],
      sectorsList: {},
      industriesList: {},
      sectorsListAll: [],
      industriesListAll: [],
      researchDocumentGroupList: [],
      ratingAgenciesMap: {},
    };
  }
  const mappings = handleMapping(
    productsList,
    regionsList,
    toolsList,
    headerMapping,
  );
  return {
    defaultActiveMapping: mappings.activeMapping,
    defaultActiveRegion: mappings.defaultRegion,
    defaultActiveProduct: mappings.defaultProduct,
    isHeaderInitialized: true,
    mapping: mappings.mappedData,
    sectorsMenu: prepareSectorsMenu(sectorsList),
    industriesMenu: prepareIndustriesMenu(industriesList),
    productsList,
    regionsList,
    sectorsList: getMappedSectors(sectorsList),
    industriesList: getMappedIndustries(industriesList),
    sectorsListAll: sectorsList,
    industriesListAll: industriesList,
    researchDocumentGroupList,
    ratingAgenciesMap,
  };
};
