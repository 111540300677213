import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import throttle from 'lodash/throttle';
import { resetSearchResult } from '@/finsight/application/actions/header/actions';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import { isScreenM } from '@dealroadshow/uikit/core/styles/screen/screen';

const KEYCODE = {
  TAB: 9,
  ENTER: 13,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
};
const KEYCODESMAP = {
  9: true,
  13: true,
  37: true,
  39: true,
  38: true,
  40: true,
};

const initialCoordinates = {
  column: null,
  row: null,
};

/**
 * @param {Array} data
 * @param {Boolean} isActive
 * @param {Boolean?} isWithToolbar
 * @return {null|String} activeLink
 */
const useArrowNavigation = (data, isActive, isWithToolbar = false) => {
  const [isMobile, setIsMobile] = useState(isScreenM());
  const [coordinates, setCoordinates] = useState(initialCoordinates);
  const { push } = useRouter();

  const dispatch = useDispatch();

  const {
    setIsMobileMenuOpen,
    setIsMarketDataMenuOpen,
    setIsSearchResultsOpen,
  } = useHeaderMenuContext();

  // we need this part to avoid closure in handleKeyPress function
  const storedCoordinates = useRef(coordinates);
  const storedMatrix = useRef(data);

  useEffect(() => {
    storedCoordinates.current = coordinates;
    storedMatrix.current = data;
  }, [coordinates, data]);

  useEffect(() => {
    setCoordinates(initialCoordinates);
  }, [data]);

  const checkIfMobile = useCallback(() => {
    setIsMobile(isScreenM());
  }, []);

  useEffect(() => {
    const throttledResizeHandler = throttle(checkIfMobile, 100);
    window.addEventListener('resize', throttledResizeHandler);
    return () => {
      window.removeEventListener('resize', throttledResizeHandler);
    };
  }, [checkIfMobile]);

  const handleKeyPress = useCallback((e) => {
    if (!KEYCODESMAP[e.keyCode]) {
      return;
    }
    e.preventDefault();

    const menuMatrix = storedMatrix.current;
    const { column: currentColumn, row: currentRow } = storedCoordinates.current;

    const isInitialPosition = currentColumn === null || currentRow === null;
    const isToolbarActive = currentColumn === 0 && isWithToolbar;

    const lastColumnIndex = menuMatrix.length - 1;
    const prevColumn = (isInitialPosition || currentColumn === 0) ? lastColumnIndex : currentColumn - 1;
    const nextColumn = (isInitialPosition || currentColumn === lastColumnIndex) ? 0 : currentColumn + 1;

    const nextRow = currentRow + 1;
    const prevRow = currentRow - 1;
    const activeRow = currentRow === null ? 0 : currentRow;

    switch (e.keyCode) {
      case KEYCODE.TAB:
        if (isInitialPosition || currentRow === menuMatrix[currentColumn].length - 1) {
          setCoordinates({
            column: nextColumn,
            row: 0,
          });
          return;
        }
        if (isToolbarActive) {
          setCoordinates({
            column: 0,
            row: nextRow,
          });
          return;
        }
        setCoordinates({
          column: currentColumn,
          row: nextRow,
        });
        break;
      case KEYCODE.ENTER:
        if (isInitialPosition) {
          return;
        }
        if (menuMatrix[currentColumn][currentRow].isExternal) {
          window.open(`${ menuMatrix[currentColumn][currentRow].link }`);
          return;
        }

        push(menuMatrix[currentColumn][currentRow].link).then(() => {
          dispatch(resetSearchResult());
          setIsMobileMenuOpen(false);
          setIsMarketDataMenuOpen(false);
          setIsSearchResultsOpen(false);
        });

        setCoordinates(initialCoordinates);
        break;

      case KEYCODE.LEFT:
        if (isToolbarActive) {
          setCoordinates({
            column: 0,
            row: currentRow === 0 ? menuMatrix[0].length - 1 : prevRow,
          });
          return;
        }
        if (isWithToolbar && prevColumn === 0) {
          setCoordinates({
            column: 0,
            row: 0,
          });
          return;
        }
        setCoordinates({
          column: prevColumn,
          row: activeRow >= menuMatrix[prevColumn].length
            ? menuMatrix[prevColumn].length - 1
            : activeRow,
        });
        break;
      case KEYCODE.RIGHT:
        if (isToolbarActive) {
          setCoordinates({
            column: 0,
            row: nextRow >= menuMatrix[0].length ? 0 : nextRow,
          });
          return;
        }
        if (nextColumn === 0 && isWithToolbar) {
          setCoordinates({
            column: nextColumn,
            row: 0,
          });
          return;
        }
        setCoordinates({
          column: nextColumn,
          row: activeRow >= menuMatrix[nextColumn].length ? menuMatrix[nextColumn].length - 1 : activeRow,
        });
        break;
      case KEYCODE.UP:
        if (isInitialPosition || currentRow === 0) {
          if (isWithToolbar && currentColumn === 1) {
            setCoordinates({
              column: 0,
              row: 0,
            });
            return;
          }
          setCoordinates({
            column: prevColumn,
            row: menuMatrix[prevColumn].length - 1,
          });
          return;
        }
        setCoordinates({
          column: currentColumn,
          row: prevRow,
        });
        break;
      case KEYCODE.DOWN:
        if (isInitialPosition || currentRow === menuMatrix[currentColumn].length - 1 || isToolbarActive) {
          setCoordinates({
            column: nextColumn,
            row: 0,
          });
          return;
        }
        setCoordinates({
          column: currentColumn,
          row: nextRow,
        });
        break;
      default:
    }
  }, [dispatch, isWithToolbar]);

  useEffect(() => {
    if (isActive && data && !isMobile) {
      window.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress, isActive, data, isMobile]);

  const isIndexExist = coordinates.column != null && coordinates.row != null;
  if (!isIndexExist || data === null) {
    return null;
  }

  return data[coordinates.column][coordinates.row]?.id;
};

export default useArrowNavigation;
