import URL from '@/finsight/Router/urlConstants';
import { getParentRoute } from '@/finsight/Router/RouteWithRedirect/getNewRoute';
import Product from '@/finsight/domain/Product';
import Region from '@/finsight/domain/Region';

/**
 * @param {String} title
 */
export function getPageTitle(title) {
  const company = 'FINSIGHT';

  if (!title || title.includes(company)) {
    return title || company;
  }

  return `${ title } | ${ company }`;
}

/**
 * @param {Array<Object>} tranches
 * @param {Array<Object>} ratingAgenciesForRegion
 * @return {Object[]}
 */
export const getRatingsMapping = (tranches, ratingAgenciesForRegion) => {
  let agenciesForDisplay = {};

  ratingAgenciesForRegion.forEach((agency) => {
    if (agency.isAlwaysDisplay) {
      agenciesForDisplay[agency.id] = agency;
    }
  });

  tranches.forEach((tranche) => {
    Object.keys(tranche.ratings).forEach((ratingAgencyFromTranche) => {
      let ratingAgency = ratingAgenciesForRegion
        .find((ratingAgencyForRegion) => ratingAgencyForRegion.id === ratingAgencyFromTranche);
      if (ratingAgency?.id) {
        agenciesForDisplay[ratingAgency.id] = ratingAgency;
      }
    });
  });

  return Object
    .values(agenciesForDisplay)
      // @ts-ignore
    .sort((a, b) => a.priority - b.priority)
    .slice(0, 6);
};

/**
 * @param {Object} [filters]
 * @param {Boolean} [withParentLink]
 * @return {Object}
 */
export const getMarketOverviewBreadcrumbsRoute = (filters = {}, withParentLink = false) => {
  const {
    // @ts-ignore
    products = Product.getDefault(),
    // @ts-ignore
    regions = Region.getDefault(),
  } = filters;

  return {
    title: 'Market Overview',
    url: `${ URL.MARKET_OVERVIEW_ALL }?products=${
      encodeURI(products)
    }&regions=${
      encodeURI(regions)
    }`,
    withParentLink,
  };
};

/**
 * @param {Object} parent
 * @return {Object}
 */
export const getParentBreadcrumbsRoute = (parent) => ({
  title: parent.name,
  url: getParentRoute(parent),
});

export const getFormatUrl = (url: string): string => {
  const [basePath, query] = url.split('?');

  return query ? url : basePath;
};
